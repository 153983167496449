import { FC, useState } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as NewDocIcon } from '../../svg/ui/new-doc.svg'
import useUploadDocuments from '../../components/DocumentManager/useUploadDocuments.ts'
import {
  DealStatus,
  DocumentTypeEntity,
  useCustomerApplicationsDealQuery,
  useDocumentsQuery
} from '../../graphql/schema.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import Folder from '../../components/DocumentManager/Folder.tsx'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import EditBkiConsentDateForm from './EditBkiConsentDateForm.tsx'
import useNodes from '../../hooks/useNodes.ts'
import BkiConsentDocForm from './BkiConsentDocForm.tsx'
import { dateFormatter } from '../../utils/dateFormatter.ts'

const BkiConsent: FC<{ customerId: string; date?: string }> = ({ customerId, date }) => {
  const [dateFormOpen, setDateFormOpen] = useState(false)
  const [docFormOpen, setDocFormOpen] = useState(false)
  const [expiresAt, setExpiresAt] = useState<Date>()

  const { data: documentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: customerId,
        entityType: DocumentTypeEntity.CustomerCompany,
        type: 'Согласие на БКИ'
      }
    },
    skip: !customerId
  })
  const documents = useNodes(documentsData?.documents?.edges)

  const uploadDocuments = useUploadDocuments({
    entityId: customerId,
    entityType: DocumentTypeEntity.CustomerCompany,
    folderName: 'Согласие на БКИ'
  })

  useCustomerApplicationsDealQuery({
    variables: {
      customerId: customerId
    },
    skip: !customerId,
    onCompleted: (data) => {
      const deals =
        data?.customerCompany?.applications?.edges
          ?.map((e) => e?.node)
          .map((a) => a?.deal)
          .filter((d) => d?._id) || []
      const hasActualDeal = deals.some(
        (deal) =>
          deal?.status !== DealStatus.Terminated &&
          deal?.status !== DealStatus.Cancelled &&
          deal?.status !== DealStatus.Closed
      )
      if (date && !hasActualDeal) {
        const expiresAtRaw = new Date(date)
        expiresAtRaw.setMonth(expiresAtRaw.getMonth() + 6)
        if (new Date() > expiresAtRaw) {
          setExpiresAt(expiresAtRaw)
        }
      }
    }
  })

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Согласие на получение кредитного отчета</CardTitle>
          <CardMenu>
            <CardIconButton
              onClick={() => {
                setDateFormOpen(true)
              }}
            >
              <EditIcon />
            </CardIconButton>
          </CardMenu>
        </CardHeader>

        <div className='px-5 pb-5'>
          <div className='rounded-md bg-surface-primary shadow-card'>
            <table className='w-full border-b-1 border-grayscale-400'>
              <tbody>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-md last:rounded-r-md'>Дата согласия</td>
                  <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                    {date ? (
                      dateFormatter.format(new Date(date))
                    ) : (
                      <button
                        className='cursor-pointer text-red-50 hover:text-red-100'
                        onClick={() => {
                          setDateFormOpen(true)
                        }}
                      >
                        Добавить дату
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className='mt-5 p-5'>
              <Folder files={documents} onUpload={uploadDocuments} limit={0} canDelete={true} canUpload={true} />
            </div>

            <div className='flex w-full justify-center pb-8 pt-2'>
              <button
                className='flex items-center gap-4 text-p200 text-labels-secondary'
                onClick={() => setDocFormOpen(true)}
              >
                <NewDocIcon title='Создать согласие БКИ' />
                Создать согласие БКИ
              </button>
            </div>
          </div>

          {expiresAt && (
            <div className='mt-6 text-p200 text-base-red'>
              Согласие на получение отчета БКИ просрочено {dateFormatter.format(new Date(expiresAt))}
            </div>
          )}
        </div>
      </Card>

      <SuspenseModal open={dateFormOpen} setOpen={setDateFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditBkiConsentDateForm customerId={customerId} date={date} onDone={() => setDateFormOpen(false)} />
        </div>
      </SuspenseModal>

      <SuspenseModal open={docFormOpen} setOpen={setDocFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <BkiConsentDocForm customerId={customerId} date={date} />
        </div>
      </SuspenseModal>
    </>
  )
}

export default BkiConsent
