import { FC, useEffect, useMemo } from 'react'
import { ReactComponent as FileIcon } from '../../../../svg/icons/fileDownload.svg'
import Input from '../../../../components/Input.tsx'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CompanyData } from '../../../../types/dadata.ts'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  isIP
} from '../../../../utils/contractUtils.ts'
import {
  useClaimScheduleQuery,
  useCreatePetitionDocxMutation,
  useDealForCollectionSuspenseQuery
} from '../../../../graphql/schema.tsx'
import SubmitButton from '../../../../components/SubmitButton'
import { Company } from '../../models'
import downloadFile from '../../../../utils/downloadFileServerless.ts'
import { formatDecimal, formatNumber } from '../../../../utils/formatNumber.ts'
import MaskedNumberInput from '../../../../components/Forms/Inputs/MaskedNumberInput.tsx'
import { parseDecimalFromMasked } from '../../../../utils/parseDecimal.ts'
import { convert } from 'number-to-words-ru'
import { getCalendarFormatDate, isValidDate } from '../../../../utils/dateFormatter.ts'
import usePersonAddress from '../../../../hooks/usePersonAddress.ts'

interface ClaimFormProps {
  dealId: number
}

type Inputs = {
  company: Company
  petitionN: string
  petitionDate: string
  caseN: string
  caseDate: string
  judgeFio: string
  amount: string
  penalty: string
  duty: string
  claimDate: string
  overdueFrom: string
  overdueTo: string
}

const calculateClaimDuty = (claimAmount: number) => {
  // до 100 000 рублей - 10 000 рублей
  if (claimAmount < 100001) {
    return 10000
  }

  // от 100 001 рубля до 1 000 000 рублей - 10 000 рублей плюс 5 процентов суммы, превышающей 100 000 рублей
  if (claimAmount < 1000001) {
    return (claimAmount - 100000) * 0.05 + 10000
  }

  // от 1 000 001 рубля до 10 000 000 рублей - 55 000 рублей плюс 3 процента суммы, превышающей 1 000 000 рублей
  if (claimAmount < 10000001) {
    return (claimAmount - 1000000) * 0.03 + 55000
  }

  // от 10 000 001 рубля до 50 000 000 рублей - 325 000 рублей плюс 1 процент суммы, превышающей 10 000 000 рублей
  if (claimAmount < 50000001) {
    return (claimAmount - 10000000) * 0.01 + 325000
  }

  // свыше 50 000 000 рублей - 725 000 рублей плюс 0,5 процента суммы, превышающей 50 000 000 рублей, но не более 10 000 000 рублей (725к вне порога 10млн)
  return 725000 + Math.min((claimAmount - 50000000) * 0.005, 10000000)
}

const PetitionForm: FC<ClaimFormProps> = ({ dealId }) => {
  const { data } = useDealForCollectionSuspenseQuery({ variables: { id: `${dealId}` } })
  const deal = data?.deal

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.company?.dadata ? deal?.customerCompany.company.dadata.data : {}),
    [deal?.customerCompany]
  )

  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const IPfactAddress = usePersonAddress(deal?.customerCompany.company?.inn || '')

  const form = useForm<Inputs>({
    defaultValues: {
      company: {
        formShort: deal?.customerCompany?.company?.shortWithOpf
          ? getCompanyForm(deal?.customerCompany?.company?.shortWithOpf, companyName)
          : '',
        form: deal?.customerCompany?.company?.fullWithOpf
          ? getCompanyForm(deal?.customerCompany?.company?.fullWithOpf, companyName, true)
          : '',
        name: companyName,
        requisites: {
          kpp: !IP ? companyDadata.kpp : undefined,
          inn: deal?.customerCompany.company?.inn,
          legalAddress: IP ? IPfactAddress : companyDadata.address?.value,
          ogrn: companyDadata.ogrn || ''
        }
      },
      claimDate: getCalendarFormatDate(new Date())
    }
  })
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = form

  const date = watch('claimDate')

  useEffect(() => {
    if (IPfactAddress && IP) {
      setValue('company.requisites.legalAddress', IPfactAddress)
    }
  }, [IPfactAddress, setValue, IP])

  const [createPetition] = useCreatePetitionDocxMutation()
  const {
    data: claimScheduleData,
    refetch,
    loading: claimScheduleLoading
  } = useClaimScheduleQuery({
    variables: {
      dealId: `${dealId}`,
      claimDate: getCalendarFormatDate(isValidDate(new Date(date)) ? new Date(date) : new Date())
    }
  })
  const claimSchedule = claimScheduleData?.claimSchedule

  useEffect(() => {
    if (date) {
      refetch()
    }
  }, [date, refetch])

  useEffect(() => {
    if (!claimSchedule) return

    const calcData = claimSchedule.reduce(
      (acc, claim, index) => {
        if (claimSchedule[index - 1]?.number !== claim?.number && claim?.amount) {
          acc.totalAmount = acc.totalAmount + claim.amount
        }
        if (claim?.paidAmount) {
          acc.totalPaidAmount = acc.totalPaidAmount + claim.paidAmount
        }
        if (claim?.penaltyAmount) {
          acc.totalPenaltyAmount = acc.totalPenaltyAmount + claim.penaltyAmount
        }
        if (claim?.paidPenaltyAmount) {
          acc.totalPaidPenaltyAmount = acc.totalPaidPenaltyAmount + claim.paidPenaltyAmount
        }
        if (
          claim?.transactionPaidAt &&
          claim?.date &&
          new Date(claim.transactionPaidAt) > new Date(claim.date) &&
          claim?.amount === claim?.paidAmount
        ) {
          if (!acc.overdueFrom) {
            acc.overdueFrom = getDateFormattedString(new Date(claim.date))
          }
          acc.overdueTo = getDateFormattedString(new Date(claim.date))
        }
        if (!acc?.overdueFrom && claim?.overdueFrom) {
          acc.overdueFrom = getDateFormattedString(new Date(claim.overdueFrom))
        }
        return acc
      },
      {
        totalAmount: 0,
        totalPaidAmount: 0,
        totalPenaltyAmount: 0,
        totalPaidPenaltyAmount: 0,
        overdueFrom: '',
        overdueTo: ''
      }
    )
    const diffAmount = calcData.totalAmount - calcData.totalPaidAmount
    const diffPenaltyAmount = calcData.totalPenaltyAmount - calcData.totalPaidPenaltyAmount
    const claimAmount = diffAmount + diffPenaltyAmount
    const claimDuty = calculateClaimDuty(claimAmount)
    setValue('amount', formatDecimal(diffAmount * 100))
    setValue('penalty', formatDecimal(diffPenaltyAmount * 100))
    setValue('duty', `${Math.round(claimDuty)}`)
    setValue('overdueFrom', calcData.overdueFrom)
    setValue('overdueTo', calcData.overdueTo)
  }, [claimSchedule, setValue])

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const company = {
      ...data.company,
      requisites: {
        ...data.company.requisites,
        legalAddress: data.company.requisites.legalAddress || ''
      }
    }

    try {
      const sum = parseDecimalFromMasked(data.amount) + parseDecimalFromMasked(data.penalty)
      const wordsSum = convert(sum, {
        showNumberParts: { fractional: false },
        showCurrency: { integer: false }
      })
      const integerPart = Math.floor(sum)
      const fractionalPart = Math.round((sum - integerPart) * 100)
        .toString()
        .padStart(2, '0')

      const wordsDuty = convert(parseDecimalFromMasked(data.duty), {
        showNumberParts: { fractional: false },
        showCurrency: { integer: false }
      })

      const claimDate = new Date(data.claimDate)
      claimDate.setDate(claimDate.getDate() + 1)

      const overduePayments = `${formatNumber(integerPart * 100)} (${wordsSum.toLowerCase()}) руб. ${fractionalPart} коп., включая ${formatDecimal(parseDecimalFromMasked(data.amount) * 100)} руб. долга, ${formatDecimal(parseDecimalFromMasked(data.penalty) * 100)} руб. неустойки за период с ${data.overdueFrom} по ${data.overdueTo}, неустойки в размере 0,1% от суммы долга ${formatDecimal(parseDecimalFromMasked(data.amount) * 100)} руб. за период с ${getDateFormattedString(claimDate)} по день фактического исполнения обязательства, а также ${formatNumber(parseDecimalFromMasked(data.duty) * 100)} (${wordsDuty.toLowerCase()}) руб. в возмещение расходов по оплате государственной пошлины`

      const createPetitionResult = await createPetition({
        variables: {
          input: {
            companyName: `${deal?.customerCompany?.company?.fullWithOpf ? getCompanyForm(deal.customerCompany.company.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            shortCompanyName: `${deal?.customerCompany?.company?.shortWithOpf ? getCompanyForm(deal.customerCompany.company.shortWithOpf, companyName) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            companyInnOgrn: `ИНН ${deal?.customerCompany?.company?.inn} ${IP ? 'ОГРНИП' : 'ОГРН'} ${company?.requisites?.ogrn}`,
            companyAddress: company.requisites.legalAddress.toUpperCase(),
            petitionN: data.petitionN,
            petitionDate: getDateFormattedString(new Date(data.petitionDate)),
            caseN: data.caseN,
            caseDate: getDateFormattedString(new Date(data.caseDate)),
            judgeFio: data.judgeFio,
            overduePayments
          }
        }
      })

      const createPetitionUrl = createPetitionResult?.data?.createPetition?.url
      if (!createPetitionUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(createPetitionUrl, `${getDateFormattedInDocumentName(new Date())} Ходатайство.docx`)
    } catch (e) {
      console.error(e)
    }
  }

  if (!deal) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-2 gap-x-20 gap-y-8'>
        <Input
          type='text'
          label='Номер ходатайства'
          placeholder='АС-00-00-00/0'
          {...register('petitionN', {
            required: true
          })}
          error={errors.petitionN}
        />
        <Input label='Дата ходатайства' type='date' {...register('petitionDate', { required: true })} />
        <Input
          type='text'
          label='Номер судебного дела'
          placeholder='А00-000000/00-000-0000'
          {...register('caseN', {
            required: true
          })}
          error={errors.caseN}
        />
        <Input label='Дата решения' type='date' {...register('caseDate', { required: true })} />
        <div>
          <Input
            label='Юридический адрес'
            type='text'
            autoComplete='off'
            placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
            {...register('company.requisites.legalAddress', { required: true })}
          />
        </div>
        <Input
          label='Судья'
          type='text'
          autoComplete='off'
          placeholder='Иванов Иван Иванович'
          {...register('judgeFio', { required: true })}
        />
        <Controller
          control={control}
          name='amount'
          rules={{ required: true, min: 0 }}
          render={({ field, fieldState }) => (
            <MaskedNumberInput
              {...field}
              label='Сумма долга'
              placeholder='100 000,00'
              inputMode='decimal'
              error={fieldState?.error}
            />
          )}
        />
        <Controller
          control={control}
          name='penalty'
          rules={{ required: true, min: 0 }}
          render={({ field, fieldState }) => (
            <MaskedNumberInput
              {...field}
              label='Неустойка'
              placeholder='100 000,00'
              inputMode='decimal'
              error={fieldState?.error}
            />
          )}
        />
        <Controller
          control={control}
          name='duty'
          rules={{ required: true, min: 0 }}
          render={({ field, fieldState }) => (
            <MaskedNumberInput
              {...field}
              label='Госпошлина'
              placeholder='100 000,00'
              inputMode='decimal'
              error={fieldState?.error}
            />
          )}
        />
        <Input label='Дата искового' type='date' {...register('claimDate', { required: true })} />
      </div>
      <SubmitButton className='mt-20' loading={isSubmitting} disabled={isSubmitting || claimScheduleLoading}>
        <FileIcon className='mr-5' />
        Сгенерировать
      </SubmitButton>
    </form>
  )
}

export default PetitionForm
