import { FC, useState } from 'react'
import copyToClipboard from 'copy-to-clipboard'
import { showFizz } from './Fizz'

import { ReactComponent as CopyIcon } from '../svg/icons/copy.svg'

interface CopyInnProps {
  inn?: string
}

const messages = [
  'ИНН скопирован',
  'ИНН точно скопирован',
  'ИНН снова скопирован',
  'ИНН совсем скопирован',
  'ИНН скопирован вообще жесть',
  'Ой всё!'
]

const CopyInn: FC<CopyInnProps> = ({ inn }) => {
  const [messageIndex, setMessageIndex] = useState(0)

  return (
    <button type='button' className='inline-flex items-center justify-self-center'>
      <CopyIcon
        title='Скопировать ИНН'
        onClick={async (e) => {
          if (inn) {
            copyToClipboard(inn)
            showFizz(messages[messageIndex], e.clientX, e.clientY)
            setMessageIndex((messageIndex + 1) % messages.length)
          }
        }}
        className='ml-5 h-[18px] w-[18px] cursor-pointer text-grayscale-200 hover:text-red-100'
      />
    </button>
  )
}

export default CopyInn
