import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { SourceByIdQuery, useAddCompanyMutation, useUpdateSourceMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import SubmitButton from '../../components/SubmitButton'
import parseDecimal from '../../utils/parseDecimal'
import CommissionRewardRateList from '../../components/CommissionRewardRateList'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import MaskedPercentInput from '../../components/Forms/Inputs/MaskedPercentInput.tsx'
import { formatPercent } from '../../utils/formatNumber.ts'
import Input from '../../components/Input.tsx'
import { CompanyData } from '../../types/dadata.ts'
import getCompanyName from '../../utils/getCompanyName.ts'
import Suggestions from '../../components/Suggestions.tsx'
import useThrottledState from '../../hooks/useThrottledState.ts'
import { useCompanySuggestions } from '../../hooks/useSuggestions.ts'

interface BonusesFormProps {
  source: SourceByIdQuery['source']
  onDone: () => void
}

interface BonusesUpdateInputs {
  commissionRewardRate: string
  agentContractNumber: string
  agentContractDate: string
  company: string
}

type Company =
  | {
      inn: string
      name: {
        short_with_opf?: string
        full_with_opf?: string
      }
    }
  | undefined

const BonusesForm: FC<BonusesFormProps> = ({ source, onDone }) => {
  const { control, handleSubmit, setError, setValue, watch, register, formState } = useForm<BonusesUpdateInputs>({
    defaultValues: {
      commissionRewardRate:
        typeof source?.commissionRewardRate === 'number'
          ? formatPercent(source.commissionRewardRate) || '0'
          : undefined,
      agentContractNumber: source?.agentContractNumber,
      agentContractDate:
        source?.agentContractDate && new Date(source.agentContractDate).toISOString().split('T').shift(),
      company: source?.agentContractCompany?.shortWithOpf
    }
  })
  const [updateSource, { loading: updateSourceLoading }] = useUpdateSourceMutation()
  const [addCompany, { loading: addCompanyLoading }] = useAddCompanyMutation()

  const [company, setCompany] = useState<Company>(
    source?.agentContractCompany?.inn
      ? {
          inn: source?.agentContractCompany?.inn,
          name: {
            short_with_opf: source?.agentContractCompany?.shortWithOpf
          }
        }
      : undefined
  )

  const commissionRewardRate = watch('commissionRewardRate')
  const agentContractNumber = watch('agentContractNumber')
  const companyQuery = watch('company')

  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const loading = updateSourceLoading || addCompanyLoading

  const onSubmit: SubmitHandler<BonusesUpdateInputs> = async (data) => {
    if (loading || !data) return

    let companyId
    if (company?.inn) {
      const { data: addCompanyData } = await addCompany({
        variables: {
          input: {
            inn: company?.inn
          }
        }
      })
      companyId = addCompanyData?.addCompany?.company?._id
    }

    await updateSource({
      variables: {
        input: {
          id: `${source?._id}`,
          commissionRewardRate: data?.commissionRewardRate ? parseDecimal(data.commissionRewardRate) : null,
          agentContractNumber: data?.agentContractNumber || null,
          agentContractDate: data.agentContractDate?.length ? new Date(data.agentContractDate).toISOString() : null,
          agentContractCompany: typeof companyId === 'number' ? companyId.toString() : null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<BonusesUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Бонусы</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-10'>
          <Suggestions<CompanyData>
            suggestions={suggestions?.map((s) => ({
              key: s._id,
              title: getCompanyName(s),
              subtitle: `${s.inn} ${s.address?.value || ''}`,
              payload: s,
              lineThrough: !!s.state.liquidation_date
            }))}
            select={(suggestion) => setCompany(suggestion.payload)}
            highlight={companyQuery}
          >
            <Input
              label='Компания агента, ИП или ИНН'
              type='text'
              autoComplete='off'
              {...register('company', {
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                  }
                }
              })}
              error={formState.errors.company?.message}
            />
          </Suggestions>
          <div className='grid grid-cols-2 gap-16'>
            <Input
              label='Агентский договор'
              type='string'
              placeholder='АД220923-01'
              {...register('agentContractNumber')}
              error={formState.errors.agentContractNumber?.message}
            />
            <Input
              label='Дата договора'
              type='date'
              {...register('agentContractDate', { required: !!agentContractNumber })}
              error={formState.errors.agentContractDate?.message}
            />
          </div>
          <div>
            <Controller
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Агентское вознаграждение'
                  placeholder='1,5%'
                  inputMode='decimal'
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
              control={control}
              name='commissionRewardRate'
              rules={{ min: 0 }}
            />
            <CommissionRewardRateList
              commissionRewardRate={commissionRewardRate}
              onChange={(value) => setValue('commissionRewardRate', value)}
              className='mt-3'
            />
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default BonusesForm
