import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ApplicationsList from '../../components/ApplicationsList'
import { useCustomerCompanyApplicationsQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'

const Applications: FC = () => {
  const { id } = useParams<'id'>()

  const { data, fetchMore, loading, error } = useCustomerCompanyApplicationsQuery({
    variables: { customerCompanyId: `${id}` },
    skip: !id
  })

  const applicationsList = useNodes(data?.customerCompany?.applications?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    data?.customerCompany?.applications?.pageInfo
  )

  return (
    <div className='container flex-grow' ref={triggerRef}>
      <div className='mb-4 rounded-xl bg-surface-secondary p-5'>
        <ApplicationsList loading={loading || isFetching} applicationsList={applicationsList} error={error?.message} />
      </div>
    </div>
  )
}

export default Applications
