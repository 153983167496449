import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as TrashIcon } from '../../../svg/icons/trash.svg'
import Input from '../../../components/Input'
import downloadFile from '../../../utils/downloadFileServerless'
import checkInn from '../../../utils/innValidator'
import checkKPP from '../../../utils/kppValidator'
import { InvoiceFormInputs } from '../models'
import { CompanyData } from '../../../types/dadata'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../utils/contractUtils'
import MaskedInput from '../../../components/MaskedInput'
import {
  DealInsuranceKind,
  Kind,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useCreateInvoicePdfMutation,
  useDealCalculationsQuery,
  useDealForInvoiceSuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  usePaymentsQuery
} from '../../../graphql/schema'
import { formatDecimal } from '../../../utils/formatNumber'
import Suggestions from '../../../components/Suggestions'
import useNodes from '../../../hooks/useNodes'
import parseDecimal from '../../../utils/parseDecimal'
import SubmitButton from '../../../components/SubmitButton'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import { REDEMPTION_PAYMENT, REGULAR_VAT_RATE } from '../../../utils/constants'
import { dateFormatter } from '../../../utils/dateFormatter'
import c from 'clsx'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import MaskedInvoiceInput from '../../../components/Forms/Inputs/MaskedInvoiceNumberInput.tsx'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'

interface InvoiceFormProps {
  onDone?: () => void
  dealId: number
}

interface ProductData {
  key: string
  title: string
  value: string
  price: string
}

const InvoiceForm: FC<InvoiceFormProps> = ({ dealId }) => {
  const { data } = useDealForInvoiceSuspenseQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const [createInvoice] = useCreateInvoicePdfMutation()
  const deal = data?.deal

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE
      }
    },
    skip:
      !deal ||
      deal.amount === null ||
      deal.advanceRate === null ||
      deal.interestRate === null ||
      deal.durationMonths === null ||
      deal.comissionRate === null ||
      deal.advancePaymentDate === null
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const { data: basePaymentsData } = usePaymentsQuery({
    variables: {
      kinds: [PaymentKind.Advance, PaymentKind.Redemption],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    skip: !dealId
  })
  const basePayments = useNodes(basePaymentsData?.payments.edges)
  const advanceAmount =
    basePayments?.find((p) => p.kind === PaymentKind.Advance)?.amount || dealCalculations?.advanceAmount
  const redemptionAmount = basePayments?.find((p) => p.kind === PaymentKind.Redemption)?.amount || REDEMPTION_PAYMENT

  const { data: leasingPaymentsData } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    skip: !dealId
  })
  const payments = useNodes(leasingPaymentsData?.payments.edges)
  const monthlyPayment = payments.find((p) => p.state !== PaymentState.Paid)
  const penaltyAmount = useMemo(() => {
    return payments
      .filter((i) => i.penaltyPayments.length)
      .map((l) => l.penaltyPayments)
      .flat()
      .filter((p) => p.state !== PaymentState.Paid)
      .reduce((acc, item) => acc + item.amount, 0)
  }, [payments])

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.company?.dadata ? deal?.customerCompany.company.dadata.data : {}),
    [deal?.customerCompany]
  )
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)
  const companyFormShort = getCompanyForm(deal?.customerCompany?.company?.shortWithOpf || '', companyName)
  const feraBankAccount = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId),
    [feraBankAccounts, deal?.feraBankAccountId]
  )

  const IPfactAddress = usePersonAddress(deal?.customerCompany.company?.inn || '')

  const form = useForm<InvoiceFormInputs>({
    defaultValues: {
      company: {
        name: companyName,
        formShort: companyFormShort,
        requisites: {
          inn: deal?.customerCompany?.company?.inn,
          legalAddress: IP ? IPfactAddress : companyDadata.address?.value,
          kpp: !IP ? companyDadata.kpp : undefined
        }
      },
      feraBankAccount: deal?.feraBankAccountId
        ? {
            bankKey: feraBankAccount?.bankKey,
            bic: feraBankAccount?.bic,
            corrNumber: feraBankAccount?.corrNumber,
            name: feraBankAccount?.name,
            number: feraBankAccount?.number
          }
        : undefined
    }
  })
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    control,
    formState: { errors }
  } = form

  useEffect(() => {
    if (IPfactAddress && IP) {
      setValue('company.requisites.legalAddress', IPfactAddress)
    }
  }, [IPfactAddress, setValue, IP])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products'
  })

  const products: ProductData[] = useMemo(() => {
    const value = `по договору лизинга №${getFormattedContractNumber(deal?.contractNumber)} от ${deal?.contractDate && getDateFormattedString(new Date(deal.contractDate))} за ${companyFormShort} ${companyName} ИНН ${deal?.customerCompany?.company?.inn}`

    const baseProducts = [
      {
        key: 'advance',
        title: `Аванс → ${formatDecimal(advanceAmount ? advanceAmount * 100 : 0)}`,
        value: `Аванс ${value}`,
        price: formatDecimal(advanceAmount ? advanceAmount * 100 : 0)
      },
      {
        key: 'monthlyPayment',
        title: `Ежемесячный платёж → ${formatDecimal(monthlyPayment?.amount ? monthlyPayment?.amount * 100 : 0)}`,
        value: `Ежемесячный платёж ${value}`,
        price: formatDecimal(monthlyPayment?.amount ? monthlyPayment?.amount * 100 : 0)
      },
      {
        key: 'redemption',
        title: `Выкупной платёж → ${formatDecimal(redemptionAmount ? redemptionAmount * 100 : 0)}`,
        value: `Выкупной платёж ${value}`,
        price: formatDecimal(redemptionAmount ? redemptionAmount * 100 : 0)
      },
      {
        key: 'penalty',
        title: `Пени по договору лизинга → ${formatDecimal(penaltyAmount ? penaltyAmount * 100 : 0)}`,
        value: `Пени ${value}`,
        price: formatDecimal(penaltyAmount ? penaltyAmount * 100 : 0)
      }
    ]

    if (deal?.insuranceKind !== DealInsuranceKind.WithoutInsurance) {
      baseProducts.push({
        key: 'insurance',
        title: `Страховка → ${formatDecimal(deal?.insuranceAmount ? deal.insuranceAmount : 0)}`,
        value: `Оплата страховой премии по договору страхования №${deal?.insuranceContract} ${value}`,
        price: formatDecimal(deal?.insuranceAmount ? deal.insuranceAmount : 0)
      })
    }

    return baseProducts
  }, [deal, advanceAmount, redemptionAmount, penaltyAmount, monthlyPayment, companyName, companyFormShort])

  const [loading, setLoading] = useState(false)
  const [kind, setKind] = useState('regular')

  const fieldName = watch(`products.0.name`)
  // hasInsurance и hasPenalty подразумевают не сделку в целом, а наличие позиций на оплату в форме
  const hasInsurance = fieldName?.includes('Оплата страховой')
  const hasPenalty = fieldName?.includes('Пени')

  useEffect(() => {
    if (hasInsurance) {
      setValue('invoiceN', deal?.insuranceContract || '')
      setValue('invoiceDate', deal?.insuranceContractDate?.split('T').shift() || '')
    } else {
      setValue('invoiceDate', new Date().toISOString().split('T').shift() || '')
    }
    if (hasInsurance || hasPenalty) {
      setKind('')
    } else {
      setKind('regular')
    }
  }, [deal, hasInsurance, hasPenalty, setValue])

  const onSubmit: SubmitHandler<InvoiceFormInputs> = async (data: InvoiceFormInputs) => {
    if (loading || !deal?.contractNumber || !deal?.contractDate || !data.products.length || !data.invoiceN) return
    setLoading(true)
    try {
      const createInvoiceResult = await createInvoice({
        variables: {
          input: {
            ...data,
            invoiceDate: getDateFormattedString(new Date(data.invoiceDate)),
            contractN: getFormattedContractNumber(deal.contractNumber),
            contractDate: getDateFormattedString(new Date(deal.contractDate.split('T').shift() as string)),
            products: data.products.map((p) => ({
              ...p,
              amount: parseInt(p.amount.toString()),
              price: parseDecimal(p.price.replace(/\s/g, ''))
            })),
            vatRate: kind === 'regular' ? REGULAR_VAT_RATE : 0,
            feraBankAccount: deal?.feraBankAccountId ? data?.feraBankAccount : undefined
          }
        }
      }).catch((err: ApolloError) => {
        handleBackendErrorsToForm<InvoiceFormInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })

      const invoiceUrl = createInvoiceResult?.data?.createInvoice?.url
      if (!invoiceUrl) throw new Error('Не удалось получить ссылку на файл счёта')

      await downloadFile(
        invoiceUrl,
        hasInsurance
          ? `СЧЕТ НА ОПЛАТУ №${deal?.insuranceContract} от ${dateFormatter.format(
              new Date(deal?.insuranceContractDate as string)
            )}.pdf`
          : `${getDateFormattedInDocumentName(new Date())} Счёт №${data.invoiceN} от ${getDateFormattedString(
              new Date(data.invoiceDate)
            )}.pdf`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!deal) return null

  return (
    <section className='w-[468px] p-12 lg:w-[1048px] xl:w-[1248px]'>
      <h1 className='mb-12 font-display text-h200'>Счёт на оплату</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-14 grid grid-cols-1 gap-20 lg:grid-cols-2 xl:grid-cols-2'>
          {!hasInsurance && (
            <Controller
              name='invoiceN'
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <MaskedInvoiceInput
                  label='Номер счёта'
                  inputMode='numeric'
                  contractNumber={deal.contractNumber || ''}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          )}

          {hasInsurance && (
            <Input
              label='Номер счёта'
              type='text'
              placeholder={'ИОГ-М-00000000000'}
              {...register('invoiceN', { required: true })}
              error={errors.invoiceN}
            />
          )}

          <Input
            label='Дата'
            type='date'
            readOnly
            {...register('invoiceDate', { required: true })}
            error={errors.invoiceDate}
          />
          <div className={c((hasInsurance || hasPenalty) && 'pointer-events-none opacity-70')}>
            <RadioButtonsGroup
              onChange={(value) => setKind(value)}
              options={[
                ['НДС 20%', 'regular'],
                ['Не облагается', '']
              ]}
              checkedValue={kind}
              label='НДС'
            />
          </div>
          <Input
            readOnly
            label='Реквизиты Fera'
            type='text'
            {...register('feraBankAccount.name')}
            error={errors.feraBankAccount?.name}
          />
        </div>
        {fields.map((field, index) => {
          return (
            <div className='text-p350 mb-6 grid grid-cols-1 gap-x-20 gap-y-4 lg:grid-cols-2' key={field.id}>
              {!index && (
                <>
                  <div className='grid grid-cols-12'>
                    <div className=''>№</div>
                    <div className='col-span-11'>Товары (работы, услуги)</div>
                  </div>
                  <div className='grid grid-cols-10'>
                    <div className='col-span-2'>Кол-во</div>
                    <div className='flex justify-center'>Ед.</div>
                    <div className='col-span-6'>Цена</div>
                    <div className='' />
                  </div>
                </>
              )}
              <div className='grid grid-cols-12'>
                <div className='flex items-center'>{index + 1}</div>
                <div className='col-span-11'>
                  <Suggestions<ProductData>
                    suggestions={products?.map((product) => ({
                      key: product.key,
                      title: product.title,
                      payload: product,
                      disabled: (product.key === 'insurance' || product.key === 'penalty') && fields?.length > 1
                    }))}
                    initialState={true}
                    select={(suggestion) => {
                      setValue(`products.${index}.name`, suggestion.payload?.value || '')
                      setValue(`products.${index}.price`, suggestion.payload?.price || '')
                    }}
                  >
                    <Input
                      type='text'
                      placeholder='Выбрать'
                      autoComplete='off'
                      {...register(`products.${index}.name`, { required: true })}
                      error={errors.products?.[index]?.name}
                    />
                  </Suggestions>
                </div>
              </div>
              <div className='grid grid-cols-10'>
                <div className='col-span-2 flex items-center justify-center'>
                  <Input
                    readOnly={hasInsurance}
                    placeholder='1'
                    min={1}
                    inputMode='numeric'
                    {...register(`products.${index}.amount`, {
                      required: true,
                      validate: (value) => {
                        return !parseInt(value) ? 'Не указано кол-во' : true
                      }
                    })}
                    error={errors.products?.[index]?.amount}
                  />
                </div>
                <div className='flex items-center justify-center'>шт.</div>
                <div className='col-span-6'>
                  <MaskedInput
                    label=''
                    placeholder='10000'
                    inputMode='decimal'
                    mask='number'
                    {...register(`products.${index}.price`, {
                      required: true,
                      validate: {
                        fromSuggested: (value) => {
                          return parseInt(value) <= 0 ? 'Не указана сумма платежа' : true
                        }
                      }
                    })}
                    error={errors.products?.[index]?.price}
                  />
                </div>
                <div className='flex items-center justify-center'>
                  <TrashIcon
                    onClick={() => remove(index)}
                    className='cursor-pointer text-grayscale-250 hover:text-red-100'
                  />
                </div>
              </div>
            </div>
          )
        })}
        <div className='mb-6 mt-13 items-start'>
          {hasInsurance || hasPenalty ? (
            <p className='text-center text-p200 text-red-150'>Добавление иных позиций в текущий счет недоступно</p>
          ) : (
            <button
              type='button'
              className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250 hover:text-grayscale-200'
              onClick={() => append({ name: '', amount: '1', price: '' })}
            >
              <PlusIcon className='mr-5' />
              Добавить позицию
            </button>
          )}
        </div>
        <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2 xl:grid-cols-2'>
          <div className='flex flex-col gap-8'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Компания</h3>
              <MaskedInput
                label='ИНН'
                disabled={true}
                mask='000000000000'
                autoComplete='off'
                placeholder='9999999999'
                {...register('company.requisites.inn', {
                  required: true,
                  validate: (value) => checkInn(value) || 'Неверный ИНН'
                })}
                error={errors.company?.requisites?.inn}
              />
              <Input
                label='Короткое наименование формы общества'
                type='text'
                placeholder='ООО'
                {...register('company.formShort', { required: true })}
                error={errors.company?.formShort}
              />
              <Input
                label='Полное наименование компании'
                type='text'
                placeholder='Компания'
                {...register('company.name', { required: true })}
                error={errors.company?.name}
              />
            </div>
          </div>
          <div className='flex flex-col gap-8'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Прочие реквизиты</h3>
              <div>
                <Input
                  label='Юридический адрес'
                  type='text'
                  autoComplete='off'
                  placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                  {...register('company.requisites.legalAddress', { required: true })}
                  error={errors.company?.requisites?.legalAddress}
                />
              </div>
              {!isIP(companyDadata) && (
                <>
                  <MaskedInput
                    label='КПП'
                    type='text'
                    placeholder='999999999'
                    mask='000000000'
                    {...register('company.requisites.kpp', {
                      required: true,
                      validate: (value) => checkKPP(value) || 'Неверный КПП'
                    })}
                    error={errors.company?.requisites?.kpp}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default InvoiceForm
