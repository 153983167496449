import { FC, useEffect, useMemo, useState } from 'react'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import Input from '../../../components/Input'
import { Controller, FieldPath, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { UUIDLeasing } from '../../../utils/uuid'
import { BALANCE_HOLDER, REDEMPTION_PAYMENT, REGULAR_VAT_RATE } from '../../../utils/constants'
import checkInn from '../../../utils/innValidator'
import checkKPP from '../../../utils/kppValidator'
import { LeasingContractFormInputs, LeasingReceiverContactInfo } from '../models'
import useThrottledState from '../../../hooks/useThrottledState'
import { useAddressSuggestions } from '../../../hooks/useSuggestions'
import { AddressData, CompanyData } from '../../../types/dadata'
import Suggestions from '../../../components/Suggestions'
import getInclineFIO from '../../../utils/getInclineFIO'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  formatZero,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../utils/contractUtils'
import MaskedInput from '../../../components/MaskedInput'
import checkOgrn from '../../../utils/ogrnValidator'
import checkOgrnip from '../../../utils/ogrnipValidator'
import {
  Kind,
  PaymentKind,
  PaymentTargetType,
  useDealCalculationsSuspenseQuery,
  useDealForLeasingContractByIdSuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  usePaymentsSuspenseQuery,
  useScheduleByDealParamsSuspenseQuery,
  useCreateContractPdfMutation,
  PledgeKind,
  VatKind,
  SpecItem,
  DealInsuranceKind,
  InsuranceKind
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import parseDecimal from '../../../utils/parseDecimal'
import { formatDecimal } from '../../../utils/formatNumber'
import SubmitButton from '../../../components/SubmitButton'
import getFullName from '../../../utils/getFullName'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import downloadAndSaveFile from '../../../utils/downloadFileServerless'
import SignatorySelectInput, { KimSignatory } from '../../../components/SignatorySelectInput.tsx'
import RequisiteInput from '../../../components/Requisites/RequisiteInput.tsx'
import ContactInfoSelectInput from '../../../components/Forms/Inputs/ContactInfoSelectInput.tsx'
import useCustomerContactInfos from '../../../hooks/useCustomerContactInfos.ts'
import convertKindToVatKind from '../../../utils/convertKindToVatKind.ts'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'

interface LeasingFormProps {
  onDone?: () => void
  dealId: number
}

export interface BankDetail {
  name: string
  account: string
  correspondentAccount: string
  bic: string
}

export interface PaymentShedule {
  row: string
  date: string
  payment: number
  vat: number
  redemptionPaymentWithVat: number
}

const mapFieldPaths = new Map<FieldPath<LeasingContractFormInputs>, FieldPath<LeasingContractFormInputs>>([
  ['company.leader.name', 'leasingReceivers.0.name'],
  ['company.leader.phone', 'leasingReceivers.0.phone'],
  ['company.leader.email', 'leasingReceivers.0.email']
])

const getReconciliationDate = (date: Date) => {
  const day = date.getDate() - 3
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${formatZero(day)}.${formatZero(month)}.${formatZero(year)}`
}

const convertDealInsuranceToInsurance = (dealKind: DealInsuranceKind) => {
  switch (dealKind) {
    case DealInsuranceKind.WithInsurance:
      return InsuranceKind.WithInsurance
    case DealInsuranceKind.WithoutInsurance:
      return InsuranceKind.WithoutInsurance
    default:
      throw new Error(`Unsupported DealInsuranceKind: ${dealKind}`)
  }
}

const LeasingContractForm: FC<LeasingFormProps> = ({ dealId }) => {
  const { data } = useDealForLeasingContractByIdSuspenseQuery({ variables: { id: `${dealId}` } })
  const deal = data?.deal

  const IPfactAddress = usePersonAddress(deal?.customerCompany.company?.inn || '')

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsSuspenseQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    }
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const { data: redemptionPaymentsData } = usePaymentsSuspenseQuery({
    variables: {
      kinds: [PaymentKind.Redemption],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const redemptionPayments = useNodes(redemptionPaymentsData?.payments.edges)

  const { data: scheduleData } = useScheduleByDealParamsSuspenseQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: insuranceRate || 0,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    }
  })
  const schedule = scheduleData?.scheduleByDealParams

  const paymentSchedule: PaymentShedule[] = useMemo(() => {
    const date = new Date(deal?.advancePaymentDate || '')
    date.setDate(date.getDate() - 3)
    date.setMonth(date.getMonth() + (deal?.durationMonths || 0))

    const redemptionAmount = redemptionPayments.length
      ? redemptionPayments[0]
      : {
          amount: 1000,
          date: new Date(date),
          kind: PaymentKind.Redemption,
          targetId: dealId.toString(),
          targetType: PaymentTargetType.Deal
        }

    let redemptionAmountVat = 0
    if (redemptionAmount?.amount && deal?.vatRate) {
      redemptionAmountVat = (redemptionAmount?.amount / (100 + deal?.vatRate)) * deal?.vatRate
    }

    let advanceAmountVat = 0
    if (dealCalculations?.advanceAmount && deal?.vatRate) {
      advanceAmountVat = (dealCalculations?.advanceAmount / (100 + deal?.vatRate)) * deal?.vatRate
    }

    if (schedule && deal?.advancePaymentDate) {
      return [
        {
          row: 'Аванс',
          date: getDateFormattedString(new Date(deal?.advancePaymentDate)),
          payment: dealCalculations?.advanceAmount || 0,
          vat: Math.floor(advanceAmountVat * 100) / 100,
          redemptionPaymentWithVat: 0
        },
        ...(schedule?.map((s, i) => ({
          row: `${i + 1}`,
          date: getDateFormattedString(new Date(s.date)),
          payment: s.amount,
          vat: s.vatAmount,
          redemptionPaymentWithVat: s.redemption
        })) || []),
        {
          row: 'Выкуп',
          date: getDateFormattedString(new Date(redemptionAmount?.date)),
          payment: redemptionAmount?.amount || 0,
          vat: Math.floor(redemptionAmountVat * 100) / 100,
          redemptionPaymentWithVat: 0
        }
      ]
    }
    return []
  }, [
    dealId,
    redemptionPayments,
    schedule,
    deal?.durationMonths,
    deal?.advancePaymentDate,
    dealCalculations?.advanceAmount,
    deal?.vatRate
  ])

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const [createContract] = useCreateContractPdfMutation()

  const bankDetails = useNodes(deal?.customerCompany?.company?.bankDetails?.edges)
  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.company?.dadata ? deal?.customerCompany.company.dadata.data : {}),
    [deal?.customerCompany]
  )
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(companyDadata?.management?.post?.toLowerCase()) ||
    companyDadata?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)
  const leaderStatusGenitiveWithName = companyDadata?.management?.name
    ? (leaderStatus + ' ' + getInclineFIO(companyDadata?.management.name) + ', действующего на основании Устава').trim()
    : ''

  const receivers: LeasingReceiverContactInfo[] = [{ fio: '', _id: -1 }]

  const suppliers = useNodes(deal?.dealSupplies?.edges).map((s) => s.supplierCompany)
  const feraBankAccount = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId),
    [feraBankAccounts, deal?.feraBankAccountId]
  )

  const form = useForm<LeasingContractFormInputs>({
    defaultValues: {
      kind: deal?.kind || Kind.Regular,
      insuranceKind: deal?.insuranceKind,
      contractN: getFormattedContractNumber(deal?.contractNumber),
      contractDate: deal?.contractDate?.length ? deal.contractDate.split('T').shift() : '',
      company: {
        form: deal?.customerCompany?.company?.fullWithOpf
          ? getCompanyForm(deal?.customerCompany?.company.fullWithOpf, companyName, true)
          : '',
        formShort: deal?.customerCompany?.company?.shortWithOpf
          ? getCompanyForm(deal?.customerCompany?.company.shortWithOpf, companyName)
          : '',
        name: companyName,
        requisites: {
          inn: deal?.customerCompany.company?.inn,
          legalAddress: IP ? IPfactAddress : companyDadata.address?.value,
          ogrn: companyDadata.ogrn,
          kpp: !IP ? companyDadata.kpp : undefined,
          bankName: bankDetails?.length === 1 ? bankDetails[0].name : '',
          accountNumber: bankDetails?.length === 1 ? bankDetails[0].account : '',
          correspondentNumber: bankDetails?.length === 1 ? bankDetails[0].correspondentAccount : '',
          bik: bankDetails?.length === 1 ? bankDetails[0].bic : ''
        },
        leaderStatus: !IP ? capitalizeFirst(companyDadata?.management?.post) : undefined,
        leaderStatusGenitiveWithName: !IP ? leaderStatusGenitiveWithName.trim() : undefined,
        leader: !IP
          ? {
              name: companyDadata?.management?.name
            }
          : undefined
      },
      suppliers: suppliers.map((s) => ({
        shortName: `${s.shortWithOpf?.replace(/"/g, '')} (ИНН ${s.inn})`
      })),
      leasingReceiversContactInfos: receivers,
      payments: {
        calculation: {
          leasingAmount: formatDecimal(deal?.amount),
          advancePercentage: deal?.advanceRate,
          durationMonths: deal?.durationMonths,
          comission: deal?.comissionRate
        }
      },
      feraBankAccount: feraBankAccount
        ? {
            bankKey: feraBankAccount?.bankKey,
            bic: feraBankAccount?.bic,
            corrNumber: feraBankAccount?.corrNumber,
            name: feraBankAccount?.name,
            number: feraBankAccount?.number
          }
        : undefined,
      pledgeHolder:
        deal?.pledgeKind === PledgeKind.WithPledge && deal?.pledgeHolder
          ? {
              name: deal?.pledgeHolder?.name,
              shortName: deal?.pledgeHolder?.shortName,
              key: deal?.pledgeHolder?.key
            }
          : undefined,
      signatory: KimSignatory,
      manager: deal?.user
        ? {
            fio: getFullName(deal.user),
            phone: deal.user.phone,
            email: deal.user?.email ? deal.user.email : undefined
          }
        : undefined,
      // Через какое-то время можно будет выпилить алгоритм UUIDLeasing, когда полностью переедем на уиды с бэка в поручительствах, сейчас оставил для обратной совместимости
      uuid: deal?.uuid || UUIDLeasing()
    }
  })
  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
    formState: { errors }
  } = form

  useEffect(() => {
    if (IPfactAddress && IP) {
      setValue('company.requisites.legalAddress', IPfactAddress)
    }
  }, [IPfactAddress, setValue, IP])

  const kind = watch('kind')

  const [addressBroadcast, setAddressBroadcast] = useState<AddressData>()

  const addressBroadcastQuery = watch('addressBroadcastSubjects')
  const [throttledAddressBroadcastQuery] = useThrottledState(addressBroadcastQuery, 500)
  const addressBroadcastSuggestions = useAddressSuggestions(throttledAddressBroadcastQuery)

  useEffect(() => {
    if (!addressBroadcast) return
    setValue('addressBroadcastSubjects', addressBroadcast.value, {
      shouldValidate: true
    })
  }, [addressBroadcast, setValue])

  const guarantors = useNodes(deal?.guarantors?.edges)
  const supplies = useNodes(deal?.dealSupplies?.edges)

  const customerContactInfos = useCustomerContactInfos([dealId], deal?.customerCompany?._id.toString())

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'leasingReceiversContactInfos'
  })

  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<LeasingContractFormInputs> = async (data: LeasingContractFormInputs) => {
    if (loading || !dealCalculations) {
      return
    }
    if (
      !supplies.every(
        (s, _, arr) => !!s?.supplierCompany?.vatKind && s?.supplierCompany?.vatKind === arr[0]?.supplierCompany?.vatKind
      )
    )
      return
    if (!deal?.dealSupplies?.edges?.length) return

    const contractAmount = paymentSchedule.reduce((acc, p) => acc + p.payment, 0).toFixed(2)

    const leasingReceivers = data.leasingReceiversContactInfos.map((receiver) => {
      return {
        name: receiver.fio,
        email: receiver.email,
        phone: receiver.phone
      }
    })

    setLoading(true)

    try {
      const createContractResult = await createContract({
        variables: {
          input: {
            addressBroadcastSubjects: data.addressBroadcastSubjects,
            addressLocationSubjects: deal?.storageAddressesLeasedObject?.join('; ') || '',
            company: {
              form: data.company.form,
              formShort: data.company.formShort,
              leader: {
                name: !IP ? (data?.company?.leader?.name as string) : ''
              },
              leaderStatus: data.company.leaderStatus,
              leaderStatusGenitiveWithName: data.company.leaderStatusGenitiveWithName,
              name: data.company.name,
              requisites: {
                accountNumber: data.company.requisites.accountNumber,
                bankName: data.company.requisites.bankName,
                bik: data.company.requisites.bik,
                correspondentNumber: data.company.requisites.correspondentNumber,
                inn: data.company.requisites.inn,
                kpp: data.company.requisites.kpp,
                legalAddress: data.company.requisites.legalAddress as string,
                ogrn: data.company.requisites.ogrn
              }
            },
            contractDate: getDateFormattedString(new Date(data.contractDate)),
            contractN: data.contractN,
            guarantors: data.guarantors,
            insuranceKind: convertDealInsuranceToInsurance(data.insuranceKind as DealInsuranceKind),
            kind: convertKindToVatKind(data.kind as Kind),
            leasingReceivers: leasingReceivers,
            manager: data.manager,
            payments: {
              reconciliationDate: getReconciliationDate(new Date(deal?.advancePaymentDate || '')),
              calculation: {
                leasingAmount: parseDecimal(data.payments.calculation.leasingAmount.replace(/\s/g, '')),
                leasingAmountVat: dealCalculations?.amountVat,
                advancePercentage: data.payments.calculation.advancePercentage,
                advanceAmount: dealCalculations?.advanceAmount,
                durationMonths: data.payments.calculation.durationMonths,
                balanceHolder: BALANCE_HOLDER,
                comission: data.payments.calculation.comission,
                leasingContractAmount: parseDecimal(contractAmount),
                leasingContractAmountVat: dealCalculations?.contractAmountVat,
                appreciation: dealCalculations?.appreciation,
                redemptionPayment: REDEMPTION_PAYMENT,
                monthlyPayment: dealCalculations?.monthlyPayment,
                vat: data.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE
              },
              paymentSchedule,
              savings: {
                incomeTax: dealCalculations?.approximatelyIncomeTaxSavings,
                vat: dealCalculations?.vatSavings
              }
            },
            feraBankAccount: deal?.feraBankAccountId ? data?.feraBankAccount : undefined,
            // TODO: тупая не важная ошибка типов, потом поправить в генераторе
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            pledgeHolder:
              deal?.pledgeKind === PledgeKind.WithPledge && deal?.pledgeHolder
                ? {
                    name: deal?.pledgeHolder?.name,
                    shortName: deal?.pledgeHolder?.shortName,
                    key: deal?.pledgeHolder?.key
                  }
                : undefined,

            signatory: data.signatory,
            suppliers: data.suppliers,
            supplierCompanyVatKind: supplies[0]?.supplierCompany?.vatKind as VatKind,
            supplies: supplies.map((supply) => ({
              currency: supply.currency as string,
              specification: (supply.nomenclature as SpecItem[])?.map((item) => ({
                ...item,
                // ensure that all numbers are integers
                count: Math.round(item.count || 0),
                VATCents: Math.round(item.VATCents || 0),
                pricePerUnitCents: Math.round(item.pricePerUnitCents || 0),
                totalPriceCents: Math.round(item.totalPriceCents || 0)
              }))
            })),
            uuid: data.uuid
          }
        }
      }).catch((err: ApolloError) => {
        handleBackendErrorsToForm<LeasingContractFormInputs>(err, (fieldPath, textError) => {
          setError(
            mapFieldPaths.get(fieldPath) || fieldPath,
            { message: textError, type: 'focus' },
            { shouldFocus: true }
          )
        })
      })

      const leasingContractUrl = createContractResult?.data?.createContract?.url
      if (!leasingContractUrl) throw new Error('Не удалось создать договор лизинга, не получен url')

      downloadAndSaveFile(
        leasingContractUrl,
        `${getDateFormattedInDocumentName(new Date())} Договор лизинга №${data.contractN} от ${getDateFormattedString(
          new Date(data.contractDate)
        )}.pdf`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!deal) return null

  return (
    <section className='w-[468px] p-12 lg:w-[1048px] xl:w-[1248px]'>
      <h1 className='mb-12 font-display text-h200'>Договор лизинга</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-20 grid grid-cols-1 gap-10 lg:grid-cols-2 xl:grid-cols-3'>
          <Input
            label='Номер договора лизинга'
            type='text'
            readOnly
            placeholder='ДЛ220201-01'
            {...register('contractN', { required: true })}
            error={errors.contractN}
          />
          <Input
            label='Дата'
            type='date'
            readOnly
            {...register('contractDate', { required: true })}
            error={errors.contractDate}
          />
          <MaskedInput
            label='Стоимость предмета лизинга'
            readOnly
            placeholder='10000000'
            inputMode='decimal'
            mask='number'
            {...register('payments.calculation.leasingAmount', { required: true, min: 0 })}
            error={errors.payments?.calculation?.leasingAmount}
          />

          <Input
            label='Срок(мес.)'
            readOnly
            placeholder='12'
            min={0}
            step={1}
            inputMode='decimal'
            {...register('payments.calculation.durationMonths', { required: true, valueAsNumber: true })}
            error={errors.payments?.calculation?.durationMonths}
          />

          <Input
            label='Аванс(%)'
            readOnly
            placeholder='00000000'
            inputMode='numeric'
            min={0}
            max={100}
            step={0.01}
            {...register('payments.calculation.advancePercentage', { required: true, valueAsNumber: true })}
            error={errors.payments?.calculation?.advancePercentage}
          />

          <Input
            label='Комиссия(%)'
            readOnly
            placeholder='3'
            inputMode='numeric'
            min={0}
            max={100}
            step={0.01}
            {...register('payments.calculation.comission', { required: true, valueAsNumber: true })}
            error={errors.payments?.calculation?.comission}
          />

          <RadioButtonsGroup
            onChange={(value) => setValue('kind', value)}
            options={[
              ['Обычная (20%)', Kind.Regular],
              ['УСН (0%)', Kind.Usn],
              ['Медицина (0%)', Kind.Medicine]
            ]}
            checkedValue={kind}
            label='Вид сделки (НДС)'
            sameWidth={false}
          />

          <Input
            label='UUID'
            type='text'
            readOnly
            placeholder='bce9f9b2-843f-11ec-bf1d-12855d1c26f9-3'
            {...register('uuid', { required: true })}
            error={errors.uuid}
          />

          <Input
            readOnly
            label='Реквизиты Fera'
            type='text'
            {...register('feraBankAccount.name')}
            error={errors.feraBankAccount?.name}
          />

          <Input
            readOnly
            label='Залог'
            type='text'
            {...register('pledgeHolder.shortName')}
            error={errors.pledgeHolder?.shortName}
          />
        </div>

        <div className='grid grid-cols-1 gap-10 lg:grid-cols-2 xl:grid-cols-3'>
          <div className='flex flex-col gap-8 xl:mb-12'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Компания</h3>
              <MaskedInput
                label='ИНН'
                disabled={true}
                mask='000000000000'
                autoComplete='off'
                placeholder='9999999999'
                {...register('company.requisites.inn', {
                  required: true,
                  validate: (value) => checkInn(value) || 'Неверный ИНН'
                })}
                error={errors.company?.requisites?.inn}
              />
              <Input
                label='Наименование формы общества'
                type='text'
                placeholder='Общество с ограниченной ответственностью'
                {...register('company.form', { required: true })}
                error={errors.company?.form}
              />
              <Input
                label='Короткое наименование формы общества'
                type='text'
                placeholder='ООО'
                {...register('company.formShort', { required: true })}
                error={errors.company?.formShort}
              />
              <Input
                label='Полное наименование компании'
                type='text'
                placeholder='Компания'
                {...register('company.name', { required: true })}
                error={errors.company?.name}
              />
              {!isIP(companyDadata) && (
                <>
                  <Input
                    label='Должность ответстветственного за компанию'
                    placeholder='Генеральный директор'
                    type='text'
                    {...register('company.leaderStatus', { required: true })}
                    error={errors.company?.leaderStatus}
                  />
                  <Input
                    label='ФИО ответстветственного за компанию'
                    placeholder='Иванов Иван Иванович'
                    type='text'
                    {...register('company.leader.name', { required: true })}
                    error={errors.company?.leader?.name}
                  />
                  <Input
                    label='Кто отвечает за компанию'
                    placeholder='Генерального директора Ивана Ивановича Иванова, действующего на основании Устава'
                    type='text'
                    {...register('company.leaderStatusGenitiveWithName', { required: true })}
                    error={errors.company?.leaderStatusGenitiveWithName}
                  />
                </>
              )}
              <Suggestions<AddressData>
                suggestions={addressBroadcastSuggestions?.map((s: AddressData) => ({
                  key: s._id,
                  title: s.value,
                  payload: s
                }))}
                select={(s) => setAddressBroadcast(s.payload)}
              >
                <Input
                  label='Адрес передачи предмета лизинга'
                  type='text'
                  autoComplete='off'
                  placeholder='Москва, Алтуфьевское шоссе, д.37, стр.2'
                  {...register('addressBroadcastSubjects', { required: true })}
                  error={errors.addressBroadcastSubjects}
                />
              </Suggestions>
            </div>
          </div>

          <div className='relative flex flex-col gap-8 xl:mb-12'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Прочие реквизиты</h3>
              <div>
                <Input
                  label='Юридический адрес'
                  type='text'
                  autoComplete='off'
                  placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                  {...register('company.requisites.legalAddress', { required: true })}
                  error={errors.company?.requisites?.legalAddress}
                />
              </div>
              {!isIP(companyDadata) && (
                <>
                  <MaskedInput
                    label='КПП'
                    type='text'
                    placeholder='999999999'
                    mask='000000000'
                    {...register('company.requisites.kpp', {
                      required: true,
                      validate: (value) => checkKPP(value) || 'Неверный КПП'
                    })}
                    error={errors.company?.requisites?.kpp}
                  />
                </>
              )}
              <MaskedInput
                label={isIP(companyDadata) ? 'ОГРНИП' : 'ОГРН'}
                type='text'
                mask='000000000000000'
                placeholder='9999999999999'
                {...register('company.requisites.ogrn', {
                  required: true,
                  validate: (value) => (isIP(companyDadata) ? checkOgrnip(value) : checkOgrn(value))
                })}
                error={errors.company?.requisites?.ogrn}
              />
              <Controller
                name='company.requisites'
                rules={{ validate: (value) => (!value?.bankName ? 'Обязательное поле' : true) }}
                control={control}
                render={({ field, fieldState }) => (
                  <RequisiteInput
                    onChange={(value) => field.onChange(value || '')}
                    value={field.value}
                    error={fieldState.error}
                    bankDetails={bankDetails}
                  />
                )}
              />
            </div>
          </div>

          <div className='flex flex-col gap-8 lg:col-span-2 xl:col-span-1 xl:mb-12'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'> Контактные лица </h3>
              {fields.map((field, index) => {
                return (
                  <div key={field.id} className='flex flex-col gap-8'>
                    <Controller
                      name={`leasingReceiversContactInfos.${index}`}
                      control={control}
                      rules={{ validate: (value) => (!value?.fio ? 'Обязательное поле' : true) }}
                      render={({ field, fieldState }) => (
                        <ContactInfoSelectInput
                          selected={field.value}
                          onChange={(selected) => field.onChange(selected)}
                          error={fieldState.error}
                          label='Контактное лицо клиента'
                          contactInfos={customerContactInfos}
                        />
                      )}
                    />
                    {index > 0 && (
                      <div className='flex items-start xl:h-[83px]'>
                        <button
                          type='button'
                          className='flex h-25 w-full items-center justify-center rounded-lg border-1 border-dashed border-red-150 text-grayscale-250'
                          onClick={() => remove(index)}
                        >
                          Удалить получателя
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
              <div className='flex h-[83px] items-start'>
                <button
                  type='button'
                  className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250'
                  onClick={() => append({ fio: '', _id: -1 })}
                >
                  Добавить получателя
                </button>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-8 lg:col-span-3 xl:col-span-1 xl:mb-12'>
            <div className='border-t-1 border-grayscale-400 pt-15'>
              <h3 className='mb-8 text-center font-display text-h300'>Подписант Фера</h3>
              <Controller
                name='signatory'
                control={control}
                render={({ field }) => (
                  <SignatorySelectInput
                    selected={field.value}
                    onChange={(selected) => field.onChange(selected)}
                    error={errors?.signatory?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className='flex flex-col gap-8 lg:col-span-3 xl:col-span-3 xl:mb-12'>
            <div className='flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Поставщики</h3>
              <div className='flex flex-wrap gap-10'>
                {suppliers.map((s, index) => (
                  <div
                    key={s._id}
                    className='md:flex-basis-gap-20-2 xl:flex-basis-gap-20-3 flex basis-full flex-col gap-8 xl:max-w-[580px]'
                  >
                    <Input
                      label='Короткое наименование'
                      placeholder='ООО «Поставщик»'
                      {...register(`suppliers.${index}.shortName`, { required: true })}
                      error={errors.suppliers?.[index]?.shortName}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {!!guarantors.length && (
            <div className='flex flex-col gap-8 lg:col-span-3 xl:col-span-3 xl:mb-12'>
              <div className='flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
                <h3 className='text-center font-display text-h300'>Поручители</h3>
                <div className='flex flex-wrap gap-10'>
                  {guarantors.map((g, index) => {
                    return (
                      <div
                        key={g._id}
                        className='md:flex-basis-gap-20-2 xl:flex-basis-gap-20-3 flex basis-full flex-col gap-8 xl:max-w-[580px]'
                      >
                        <Input
                          label='ФИО или Компания'
                          readOnly
                          placeholder='Петров Петр Петрович или ООО «ФЕРА» (ИНН 7111111111)'
                          {...register(`guarantors.${index}.name`, { required: true })}
                          value={
                            g.contact
                              ? g.contact?.fio
                              : `${
                                  g.company?.shortWithOpf && g.company?.name
                                    ? getCompanyForm(g.company.shortWithOpf, g.company.name)
                                    : ''
                                } «${g.company?.name}» (ИНН ${g.company?.inn})`
                          }
                          error={errors.guarantors?.[index]?.name}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default LeasingContractForm
