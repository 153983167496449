import { FC, useMemo } from 'react'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { TransactionsByDealIdQuery, useTransactionsByDealIdQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import { NodeType } from '../../../types'
import { paymentKindDict } from '../../../utils/dictionaries'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import useScrollPagination from '../../../hooks/useScrollPagination.ts'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'
// import c from 'clsx'

interface TransactionTableProps {
  dealId: string
}

export type Transaction = NodeType<TransactionsByDealIdQuery['transactions']>

const TransactionTable: FC<TransactionTableProps> = ({ dealId }) => {
  const {
    data: transactionsData,
    loading,
    error,
    fetchMore
  } = useTransactionsByDealIdQuery({
    variables: { dealId: dealId }
  })

  const transactions = useNodes(transactionsData?.transactions.edges)
  const { wrapperRef, isFetching } = useScrollPagination(fetchMore, transactionsData?.transactions?.pageInfo)

  const transactionTableConfig = useMemo<GridViewConfig<Transaction>>(
    () => ({
      grid: 'grid-cols-auto-3',
      columns: [
        { title: 'Тип платежа', value: (t) => paymentKindDict[t.payment.kind] },
        { title: 'Дата', value: (t) => dateFormatter.format(new Date(t.createdAt)) },
        {
          title: 'Сумма',
          value: (t) => (
            // логика для стилей не завершена, пока с бэка прилетают только положительные платежи
            // нужно будет прикрутить src/components/TransactionAmount.tsx
            <div className='text-green-600'>
              {'+ '}
              {formatMoneyDecimal(t.amount)}
            </div>
          )
        }
        // { title: 'Источник', value: (t) => t.customerAccount.customerCompany.company.shortWithOpf }
      ]
    }),
    []
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle>Транзакции</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5' ref={wrapperRef}>
        <GridView
          data={transactions}
          config={transactionTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
    </Card>
  )
}

export default TransactionTable
