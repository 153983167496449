import { FC } from 'react'
import { useParams, Routes, Route } from 'react-router-dom'
import { useCustomerByIdQuery } from '../../graphql/schema'
import { companyColorDict, CompanyStatus, companyStatusDict, companyTextColorDict } from '../Supplier/models'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import c from 'clsx'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'
import useAccessCheck from '../../hooks/useAccessCheck.ts'

import CustomerInfo from './CustomerInfo'
import CustomerDocuments from './Documents'
import CustomerCompanyApplications from './Applications'
import CustomerCompanyScoring from './Scoring'
import CustomerCompanyDeals from './Deals'
import CustomerBankOpertaions from './BankOperations'
import PaymentDiscipline from './PaymentDiscipline'

const Customer: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading } = useCustomerByIdQuery({
    variables: { id: `${id}` },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })

  const company = data?.customerCompany?.company
  const status = company?.dadata?.data?.state?.status as CompanyStatus

  const hasAccessScoringView = useAccessCheck('customer_company.scoring.view')

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex items-start justify-between'>
          <div>
            <EntityInfo>Компания</EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName value={company?.shortWithOpf} loading={loading} />
              <CopyInn inn={company?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={company?.inn} />
          </div>
        </div>
        <div className='mb-12 flex gap-x-4'>
          {!!companyStatusDict[status] && (
            <div
              className={c('rounded-md px-5 py-3 font-medium', companyColorDict[status], companyTextColorDict[status])}
            >
              {companyStatusDict[status]}
            </div>
          )}
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Движения средств' to='payments' />
          <EntityNavItem title='Документы' to='docs' />
          <EntityNavItem title='Заявки' to='applications' />
          {hasAccessScoringView && <EntityNavItem title='Скоринг' to='scoring' />}
          <EntityNavItem title='Сделки' to='deals' />
          <EntityNavItem title='Платёжная дисциплина' to='payment-discipline' />
        </EntityNav>
      </EntityHeader>
      <EntityPage>
        <Routes>
          <Route index element={<CustomerInfo />} />
          <Route path='payments' element={<CustomerBankOpertaions />} />
          <Route path='docs' element={<CustomerDocuments />} />
          <Route path='applications' element={<CustomerCompanyApplications />} />
          <Route path='scoring' element={<CustomerCompanyScoring />} />
          <Route path='deals' element={<CustomerCompanyDeals />} />
          <Route path='payment-discipline' element={<PaymentDiscipline />} />
        </Routes>
      </EntityPage>
    </div>
  )
}

export default Customer
