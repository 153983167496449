import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DocumentTypeEntity, useSourceByIdQuery } from '../../graphql/schema'
import Modal from '../../components/Modal'
import BonusesForm from './BonusesForm'
import useAccessCheck from '../../hooks/useAccessCheck'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card'
import { formatPercent } from '../../utils/formatNumber.ts'
import { dateFormatter } from '../../utils/dateFormatter.ts'
import EntityFolder from '../../components/DocumentManager/EntityFolder.tsx'
import CopyInn from '../../components/CopyInn.tsx'
import NavLink from '../../components/NavLink.tsx'

const Bonuses: FC = () => {
  const { id } = useParams<'id'>()
  const { data, refetch } = useSourceByIdQuery({ variables: { id: `${id}` }, skip: !id })
  const source = data?.source

  const hasAccessManage = useAccessCheck('source.agent_commission.manage')

  const [bonusesFormOpen, setBonusesFormOpen] = useState(false)

  if (!source) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Бонусы</CardTitle>
            <CardMenu>
              {hasAccessManage && (
                <CardIconButton onClick={() => setBonusesFormOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              )}
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='rounded-l-md px-8 py-6 text-labels-secondary'>Агентское вознаграждение</td>
                    <td className='rounded-r-md px-8 py-6 text-right'>
                      {typeof source?.commissionRewardRate === 'number'
                        ? formatPercent(source.commissionRewardRate) || '0 %'
                        : '-'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='rounded-l-md px-8 py-6 text-labels-secondary'>Компания агента</td>
                    <td className='rounded-r-md px-8 py-6 text-right'>
                      {source?.agentContractCompany ? (
                        <div className='flex items-center justify-end'>
                          <NavLink
                            className='cursor-pointer hover:text-base-red'
                            to={`/companies/${source?.agentContractCompany?._id}`}
                          >
                            {source?.agentContractCompany?.shortWithOpf}
                          </NavLink>
                          <CopyInn inn={source?.agentContractCompany?.inn} />
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='mt-8 rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='rounded-l-md px-8 py-6 text-p200 font-medium'>Агентский договор</td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='rounded-l-md px-8 py-6 text-labels-secondary'>Номер договора</td>
                    <td className='rounded-r-md px-8 py-6 text-right'>{source?.agentContractNumber || '-'}</td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='rounded-l-md px-8 py-6 text-labels-secondary'>Дата договора</td>
                    <td className='rounded-r-md px-8 py-6 text-right'>
                      {source?.agentContractDate ? dateFormatter.format(new Date(source.agentContractDate)) : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='border-t-1 border-separators-primary px-8 py-6'>
                <EntityFolder
                  canUpload
                  canDelete
                  entityId={source._id}
                  entityType={DocumentTypeEntity.Source}
                  folderName='Агентский договор'
                />
              </div>
            </div>
          </div>
        </Card>
      </div>

      <Modal open={bonusesFormOpen} setOpen={setBonusesFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <BonusesForm
            source={source}
            onDone={() => {
              refetch()
              setBonusesFormOpen(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Bonuses
