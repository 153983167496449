import { FC } from 'react'
import { dateTimeFormatter } from '../../utils/dateFormatter'
import { useCustomersListQuery, CustomersListQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import SearchBar, { FilterButton } from '../../components/Search'
import FilterForm, { useCustomerFilter } from './FilterForm'
import SelectedFilters from './SelectedFilters'
import useUpdateEffect from '../../hooks/useUpdateEffect'

type Customer = NodeType<CustomersListQuery['customerCompanies']>

const customerTableConfig: GridViewConfig<Customer> = {
  grid: 'grid-cols-customer',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    { title: 'Название', value: (c) => c?.company?.shortWithOpf },
    { title: 'ИНН', value: (c) => c.company?.inn, numeric: true },
    {
      title: 'Дата обновления',
      value: (c) => dateTimeFormatter.format(new Date(c.company?.created || '')),
      numeric: true
    }
  ],
  rows: {
    link: (c) => `/customers/${c._id}`
  }
}

const Customers: FC = () => {
  const { filterState, setFilterState, filterActive, query, setQuery, variables } = useCustomerFilter()

  const {
    data: customerCompaniesListQuery,
    refetch,
    fetchMore,
    loading,
    error
  } = useCustomersListQuery({ fetchPolicy: 'cache-and-network', variables })
  const customerCompaniesList = useNodes(customerCompaniesListQuery?.customerCompanies?.edges)
  const { wrapperRef, isFetching } = useScrollPagination(
    fetchMore,
    customerCompaniesListQuery?.customerCompanies?.pageInfo
  )

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  return (
    <div className='container flex-grow px-6 py-15'>
      <div className='mb-8 rounded-xl bg-surface-secondary p-5'>
        <div className='rounded-md bg-white-0 shadow-xs'>
          <div className='flex items-start justify-between p-10'>
            <div>
              <h1 className='font-display text-h100'>Компании-клиенты</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <FilterButton active={filterActive}>
          <FilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='rounded-xl bg-surface-secondary p-5' ref={wrapperRef}>
        <GridView
          data={customerCompaniesList}
          config={customerTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
    </div>
  )
}

export default Customers
